import React, { useState, useEffect } from 'react';
import { Layout } from '@nimbus-ds/patterns';
import {
  Text,
  Skeleton,
  Button,
  Icon,
  Box,
  Tag,
  Title,
  Card,
  Select,
  Input,
  Toggle,
  Tooltip,
  Link
} from '@nimbus-ds/components';
import {
  ChevronDownIcon,
  PlusCircleIcon,
  QuestionCircleIcon,
  ExternalLinkIcon,
  InfoCircleIcon
} from '@nimbus-ds/icons';
import { useParams } from 'react-router-dom';
import '@nimbus-ds/styles/dist/index.css';
import { useTranslation } from 'react-i18next';
import nexo from '../../nexoClient';
import { navigateHeader } from '@tiendanube/nexo/helpers';

import axios from 'axios';

const BusinessRuleDetail: React.FC = () => {
  const { t } = useTranslation();
  const { id }: { id: string } = useParams();
  const [businessRule, setBusinessRule] = useState<BusinessRule>();
  const [conditionals, setConditionals] = useState([]);
  const [condition1, setCondition1] = useState<Conditional>();
  const [condition2, setCondition2] = useState<Conditional>();
  const [isMounted, setIsMounted] = useState(true);
  const [loading, setLoading] = useState(false);
  const [shippingCarries, setShippingCarrries] = useState<number>(0);
  const [paymentProviders, setPaymentProviders] = useState<number>(0);
  const [showSecondSection, setShowSecondSection] = useState(false);
  const [secondSectionButtonText, setSecondSectionButtonText] = useState(
    'Añadir segunda condición'
  );

  useEffect(() => {
    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/business-rules', text: 'Volver' });
  }, []);

  interface BusinessRule {
    id: number;
    name: string;
    description: string;
    cant_targets: string;
    conditionals: Conditional[];
    disable_cart: boolean;
    disable_cart_text: string;
    cant_shipping_carriers: number;
    cant_payment_providers: number;
    type: string;
    priority: string;
    is_checked: boolean;
    is_default: boolean;
    is_active: boolean;
  }

  interface Conditional {
    guard_name: string;
    number_value: number;
    id: string;
    is_active: boolean;
    name: string;
    type: string;
  }

  const getBusinessRule = async () => {
    setLoading(true);
    axios
      .get('business_rules/' + id)
      .then((response) => {
        if (isMounted && response.data.status) {
          const updatedBusinessRules = response.data.business_rule;
          setShippingCarrries(response.data.total_cant_shipping_carriers ?? 0);
          setPaymentProviders(response.data.total_cant_payment_providers ?? 0);
          setBusinessRule(updatedBusinessRules);

          console.log(updatedBusinessRules);

          if (updatedBusinessRules && updatedBusinessRules.conditionals) {
            if (
              updatedBusinessRules.conditionals[0] &&
              updatedBusinessRules.conditionals[0].id
            ) {
              setCondition1({ ...updatedBusinessRules.conditionals[0] });
            }
            if (
              updatedBusinessRules.conditionals[1] &&
              updatedBusinessRules.conditionals[1].id
            ) {
              setCondition2({ ...updatedBusinessRules.conditionals[1] });
            }
          }
        }

        return true;
      })
      .catch((error) => {
        console.log('Error exception fetching Business Rules:', error);
        return false;
      });
    setLoading(false);
  };

  const getConditionals = async () => {
    setLoading(true);
    if (businessRule) {
      axios
        .get('conditionals', {
          params: {
            type: businessRule.type
          }
        })
        .then((response) => {
          if (response.data.status) {
            const updatedConditionals = response.data.conditionals.data;
            setConditionals(updatedConditionals);
          }
          return true;
        })
        .catch((error) => {
          console.log('Error exception fetching Conditionals:', error);
          return false;
        });
      setLoading(false);
    }
  };

  const toggleSecondSection = () => {
    setShowSecondSection(!showSecondSection);

    if (!showSecondSection) {
      setSecondSectionButtonText('Quitar segunda condición');
    } else {
      setSecondSectionButtonText('Añadir segunda condición');
    }
  };

  useEffect(() => {
    getBusinessRule();
  }, []);

  useEffect(() => {
    getConditionals();
  }, [businessRule]);

  return (
    <div>
      {!businessRule ? (
        <Skeleton height="2rem" width="9rem" borderRadius="5" />
      ) : (
        <Layout columns="1" margin="auto" marginY="10" width="80%">
          <Layout columns="1">
            <Layout.Section height="100%">
              <Box
                alignItems="center"
                display="flex"
                justifyContent="space-between"
              >
                <Title as="h1">Detalles de regla</Title>
                <Link
                  as="a"
                  href={`https://app.lerentools.com/business-rules/${businessRule.id}`}
                  target="_blank"
                  textDecoration="none"
                >
                  <Button appearance="primary">
                    <Box display="flex" gap="1" alignItems="flex-start">
                      Editar
                      <Icon
                        color="neutral-background"
                        source={<ExternalLinkIcon />}
                      />
                    </Box>
                  </Button>
                </Link>
              </Box>
            </Layout.Section>
            <Layout.Section>
              <Card>
                <Card.Header>
                  <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Title as="h2">{businessRule.name}</Title>
                    <Tag
                      appearance={
                        businessRule.is_active ? 'success' : 'warning'
                      }
                    >
                      {businessRule.is_active ? 'Activa' : 'Inactiva'}
                    </Tag>
                  </Box>
                </Card.Header>
                <Card.Body>
                  <Box display="flex" flexDirection="column" gap="4">
                    <Box>
                      <Title as="h5">Descripción</Title>
                      <Text as="p">
                        {businessRule.description
                          ? businessRule.description
                          : '-'}
                      </Text>
                    </Box>
                    <Box>
                      <Title as="h5">Tipo de regla</Title>
                      <Text fontSize="base" color="primary-interactive">
                        {t(businessRule.type)}
                      </Text>
                    </Box>
                    <Box>
                      <Title as="h5">Asociados</Title>
                      <Text fontSize="base" color="primary-interactive">
                        {businessRule.cant_targets}
                      </Text>
                    </Box>
                  </Box>
                </Card.Body>
              </Card>
            </Layout.Section>
          </Layout>
          <Layout columns="1" marginBottom="6">
            <Card>
              <Layout.Section>
                <Box marginY="2">
                  <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="space-between"
                    marginBottom="4"
                    gap="2"
                  >
                    <Title as="h2">Condiciones</Title>
                    {/* <Button
                      
                      appearance="transparent"
                      onClick={toggleSecondSection}
                    >
                      <Icon
                        color="neutral-background"
                        source={<PlusCircleIcon />}
                      />
                      {secondSectionButtonText}
                    </Button> */}
                  </Box>
                  <Text fontSize="base">Condición 1</Text>
                  <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="space-between"
                    gap="2"
                    marginTop="2"
                  >
                    <Box width="100%">
                      <Select
                        appearance="neutral"
                        id="conditionals"
                        name="conditionals"
                        value={condition1 ? condition1.id : ''}
                        disabled
                      >
                        {!condition1 && (
                          <Select.Option
                            key=""
                            value=""
                            label=""
                            hidden
                          ></Select.Option>
                        )}
                        {conditionals ? (
                          conditionals.map((conditional: Conditional) => (
                            <Select.Option
                              key={conditional.id}
                              value={conditional.id}
                              label={conditional.name}
                            ></Select.Option>
                          ))
                        ) : (
                          <Select.Skeleton />
                        )}
                      </Select>
                    </Box>
                    <Box width="200px">
                      <Input
                        value={condition1 ? condition1.number_value : ''}
                        disabled
                      />
                    </Box>
                  </Box>
                </Box>
              </Layout.Section>
              {/* {showSecondSection && ( */}
              <Layout.Section gap="2" marginTop="2">
                <Text fontSize="base">Condición 2</Text>
                <Box
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                  gap="2"
                  marginBottom="2"
                >
                  <Box width="100%">
                    <Select
                      appearance="neutral"
                      id="conditionals"
                      name="conditionals"
                      value={condition2 ? condition2.id : '-'}
                      disabled
                    >
                      {!condition2 && (
                        <Select.Option
                          key=""
                          value=""
                          label=""
                          hidden
                        ></Select.Option>
                      )}
                      {conditionals ? (
                        conditionals.map((conditional: Conditional) => (
                          <Select.Option
                            key={conditional.id}
                            value={conditional.id}
                            label={conditional.name}
                          ></Select.Option>
                        ))
                      ) : (
                        <Select.Skeleton />
                      )}
                    </Select>
                  </Box>
                  <Box width="200px">
                    <Input disabled />
                  </Box>
                </Box>
              </Layout.Section>
              {/* )} */}
              <Text fontSize="base">
                *Esta regla se aplicará en tu tienda sólo cuando se cumplan
                estas condiciones.
              </Text>
            </Card>
          </Layout>
          <Layout columns="1" marginBottom="6">
            <Card>
              <Layout.Section>
                <Box marginY="2">
                  <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="space-between"
                    marginBottom="4"
                  >
                    <Title as="h2">Carrito</Title>
                    <Tag
                      appearance={
                        businessRule.disable_cart ? 'danger' : 'success'
                      }
                    >
                      {businessRule.disable_cart ? 'Bloqueado' : 'Habilitado'}
                    </Tag>
                  </Box>
                  <Box
                    display="flex"
                    backgroundColor="neutral-surfaceDisabled"
                    borderColor="neutral-surfaceHighlight"
                    borderWidth="1px"
                    borderStyle="solid"
                    borderRadius="10px"
                    padding="4"
                    marginBottom="2"
                    gap="4"
                  >
                    <Icon
                      source={
                        <Tooltip
                          content="Este mensaje se mostrará sobre el botón 'Continuar compra' en el carrito."
                          position="top"
                        >
                          <InfoCircleIcon />
                        </Tooltip>
                      }
                    />
                    <Text fontSize="base" fontWeight="bold">
                      Esto habilita o deshabilita la opcion para avanzar al
                      checkout.
                    </Text>
                  </Box>
                  <Text fontSize="base">Leyenda a mostrar en el carrito</Text>
                  <Box
                    alignItems="center"
                    display="flex"
                    position="relative"
                    justifyContent="space-between"
                    gap="2"
                    marginTop="2"
                    marginBottom="4"
                  >
                    <Input
                      value={
                        businessRule.disable_cart_text
                          ? businessRule.disable_cart_text
                          : ''
                      }
                      disabled
                    />
                    <Box position="absolute" right="0" marginRight="2">
                      <Icon
                        source={
                          <Tooltip
                            content="Este mensaje se mostrará sobre el botón 'Continuar compra' en el carrito."
                            position="top"
                          >
                            <QuestionCircleIcon />
                          </Tooltip>
                        }
                      />
                    </Box>
                  </Box>
                  <Text fontSize="base">
                    * Al estar habilitado permite avanzar al checkout y al estar
                    deshabilitado muestra el mensaje y bloquea el botón de
                    comprar para avanzar al checkout.
                  </Text>
                </Box>
              </Layout.Section>
            </Card>
          </Layout>
        </Layout>
      )}
    </div>
  );
};

export default BusinessRuleDetail;
