import { BusinessRuleDetail, BusinessRulesPage, LoginPage } from '../../pages';

const routes = [
  {
    path: '/login',
    component: LoginPage,
    isPrivate: false
  },
  {
    path: '/business-rules',
    component: BusinessRulesPage,
    isPrivate: true
  },
  {
    path: '/business-rules/:id',
    component: BusinessRuleDetail,
    isPrivate: true
  }
];

export default routes;
