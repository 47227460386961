import ReactDOM from 'react-dom';
import App from './App';
import { AuthProvider } from './stores/auth.js';
import './index.css';
import './i18n';
import '@nimbus-ds/styles/dist/index.css';
import '@nimbus-ds/styles/dist/themes/dark.css';

ReactDOM.render(
  <AuthProvider>
    <App />
  </AuthProvider>,
  document.getElementById('root')
);
