import React, { useState } from 'react';
import { Layout, FormField } from '@nimbus-ds/patterns';
import { useHistory } from 'react-router-dom';
import { RocketIcon } from '@nimbus-ds/icons';
import {
  Box,
  Button,
  useToast,
  Input,
  Text,
  Title,
  Spinner,
  Alert,
  Tag,
  Card,
  Link
} from '@nimbus-ds/components';
import { useAuth } from '../../stores/auth';

const LoginPage: React.FC = () => {
  const { login, state } = useAuth();
  const history = useHistory();
  const { addToast } = useToast();
  const [error, setError] = useState(false);
  //const { state, logout } = useAuth();
  const [user, setUser] = useState({
    email: '',
    password: ''
  });

  const handleChange = (e: any) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value
    });
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(email)) {
      setError(true);
      return false;
    } else {
      setError(false);
      return true;
    }
  };

  const handleKeypress = (e: any) => {
    if (e.keyCode === 13) {
      handleLogin();
    }
  };

  const handleLogin = async () => {
    const isFormCorrect = validateEmail(user.email);
    if (isFormCorrect) {
      try {
        await login(user);
        history.push('/business-rules');
        addToast({
          id: 'login-toast',
          type: 'success',
          text: 'Succesfull login! 😀',
          duration: 4000
        });
      } catch (error) {
        console.log(error);
        setError(true);
      }
    }
  };

  return (
    <Layout
      height="auto"
      columns="2 - symmetric"
      marginTop="20"
      padding="10"
      backgroundColor="neutral-surface"
    >
      <Layout.Section>
        <Title as="h1">
          <Box display="flex" gap="2" alignItems="center">
            Llevá tu tienda al siguiente nivel
            <RocketIcon size="large" />
          </Box>
        </Title>
        <Text as="span" color="primary-interactive">
          Las herramientas necesarias para potenciar tu eCommerce al máximo.
        </Text>
        <Box display="flex" flexWrap="wrap" gap="2">
          <Tag>Preventa</Tag>
          <Tag>Personalizados</Tag>
          <Tag>Publicaciones programadas</Tag>
          <Tag>Tiempo de fabricación</Tag>
          <Tag>Reglas de negocio avanzadas</Tag>
        </Box>
        <Box>
          <img
            src="assets/images/LerenTools_Nimbus.png"
            alt="login-banner"
            width="50%"
          />
        </Box>
      </Layout.Section>

      <Layout.Section maxWidth="500px">
        <Card>
          <img
            src="assets/images/logo_lerentools.png"
            alt="login-banner"
            width="35%"
          />
          <Card.Body>
            <Box display="flex" flexDirection="column" gap="2" marginY="4">
              {error && (
                <Alert
                  appearance="danger"
                  title="Tu email o contraseña son incorrectos."
                >
                  Revisalos y volvé a intentar.
                </Alert>
              )}
              <FormField label="Email">
                <Input
                  name="email"
                  type="text"
                  onChange={(event) => {
                    handleChange(event);
                  }}
                  onKeyDown={handleKeypress}
                />
              </FormField>
              <FormField label="Contraseña">
                <Input.Password
                  name="password"
                  onChange={(event) => {
                    handleChange(event);
                  }}
                  onKeyDown={handleKeypress}
                />
              </FormField>
              <Link
                appearance="primary"
                as="a"
                href="https://app.lerentools.com/password_recovery"
                target="_blank"
              >
                ¿Olvidaste tu contraseña?
              </Link>
            </Box>
          </Card.Body>
          <Card.Footer>
            {state.loading ? (
              <Button appearance="primary">
                <Spinner size="small" color="neutral-background" />
              </Button>
            ) : (
              <Button type="submit" appearance="primary" onClick={handleLogin}>
                Log in
              </Button>
            )}
          </Card.Footer>
        </Card>
      </Layout.Section>
    </Layout>
  );
};

export default LoginPage;
