import React, { useState, useEffect } from 'react';
import { Page } from '@nimbus-ds/patterns';
import {
  Icon,
  Tag,
  Button,
  Box,
  Title,
  Tooltip,
  Text,
  useToast,
  Link,
  Popover,
  Table,
  Toggle
} from '@nimbus-ds/components';
import { Link as ReactLink } from 'react-router-dom';
import {
  ChevronDownIcon,
  ExclamationTriangleIcon,
  ExternalLinkIcon
} from '@nimbus-ds/icons';
import '@nimbus-ds/styles/dist/index.css';
import { useTranslation } from 'react-i18next';
import nexo from '../../nexoClient';
import { navigateHeaderRemove } from '@tiendanube/nexo/helpers';

import axios from 'axios';

const BusinessRules: React.FC = () => {
  const { t } = useTranslation();
  const { addToast } = useToast();
  const [businessRules, setBusinessRules] = useState<BusinessRule[]>([]);
  const [isMounted, setIsMounted] = useState(true);
  const [shippingCarries, setShippingCarrries] = useState<number>(0);
  const [paymentProviders, setPaymentProviders] = useState<number>(0);

  useEffect(() => {
    return () => {
      setIsMounted(false);
    };
  }, []);
  interface BusinessRule {
    id: number;
    name: string;
    cant_targets: number;
    cant_shipping_carriers: number;
    cant_payment_providers: number;
    type: string;
    priority: string;
    is_checked: boolean;
    is_default: boolean;
    is_active: boolean;
  }

  const headerButtons = (
    <>
      <Link
        as="a"
        href={`https://app.lerentools.com/dashboard`}
        target="_blank"
        textDecoration="none"
      >
        <Button>
          <Box display="flex" gap="1" alignItems="flex-start">
            Dashboard
            <Icon source={<ExternalLinkIcon />} />
          </Box>
        </Button>
      </Link>
      <Link
        as="a"
        href={`https://app.lerentools.com/business-rules/?create_rule=1`}
        target="_blank"
        textDecoration="none"
      >
        <Button appearance="primary">
          <Box display="flex" gap="1" alignItems="flex-start">
            Agregar regla de negocio
            <Icon color="neutral-background" source={<ExternalLinkIcon />} />
          </Box>
        </Button>
      </Link>
    </>
  );

  const getBusinessRules = async () => {
    await axios
      .get('business_rules')
      .then((response) => {
        if (isMounted && response.data.status) {
          const updatedBusinessRules = response.data.business_rules.data.map(
            (br: any) => {
              br.is_active = br.is_active ? true : false;
              return {
                ...br,
                is_checked: false
              };
            }
          );
          setShippingCarrries(response.data.total_cant_shipping_carriers ?? 0);
          setPaymentProviders(response.data.total_cant_payment_providers ?? 0);
          setBusinessRules(updatedBusinessRules);
        }

        return true;
      })
      .catch((error) => {
        console.log('Error exception fetching Business Rules:', error);
        return false;
      });
  };

  const handleActivateRule = async (rule: BusinessRule) => {
    const toastTimestamp = new Date().getTime();
    console.log(toastTimestamp);
    try {
      const data = {
        is_active: true
      };
      const activateResponse = await axios.put(
        'business_rules/' + rule.id + '/status',
        data
      );
      if (
        activateResponse &&
        activateResponse.data &&
        activateResponse.data.status
      ) {
        await getBusinessRules();
        addToast({
          id: `${toastTimestamp}-activeRuleSuccess`,
          type: 'success',
          text: '¡Regla de Negocio activada con éxito!',
          duration: 4000
        });
      } else {
        console.log('Error while activating Business Rule');
        addToast({
          id: `${toastTimestamp}-activeRuleError`,
          type: 'danger',
          text: 'Ocurrió un error al intentar activar la Regla de Negocio seleccionada',
          duration: 8000
        });
      }
    } catch (error) {
      console.log('Error exception while activating Business Rule:', error);
      addToast({
        id: `${toastTimestamp}-activeRuleError`,
        type: 'danger',
        text: 'Ocurrió un error al intentar activar la Regla de Negocio seleccionada',
        duration: 8000
      });
    }
  };

  const handleDeactivateRule = async (rule: BusinessRule) => {
    const toastTimestamp = new Date().getTime();
    try {
      const data = {
        is_active: false
      };
      const activateResponse = await axios.put(
        'business_rules/' + rule.id + '/status',
        data
      );
      if (
        activateResponse &&
        activateResponse.data &&
        activateResponse.data.status
      ) {
        await getBusinessRules();
        addToast({
          id: `${toastTimestamp}-deactiveRuleSuccess`,
          type: 'success',
          text: '¡Regla de Negocio desactivada con éxito!',
          duration: 4000
        });
      } else {
        console.log('Error while deactivating Business Rule');
        addToast({
          id: `${toastTimestamp}-deactiveRuleError`,
          type: 'danger',
          text: 'Ocurrió un error al intentar desactivar la Regla de Negocio seleccionada',
          duration: 8000
        });
      }
    } catch (error) {
      console.log('Error exception while deactivating Business Rule:', error);
      addToast({
        id: `${toastTimestamp}-deactiveRuleError`,
        type: 'danger',
        text: 'Ocurrió un error al intentar desactivar la Regla de Negocio seleccionada',
        duration: 8000
      });
    }
  };

  useEffect(() => {
    getBusinessRules();
    navigateHeaderRemove(nexo);
  }, []);

  useEffect(() => {
    if (businessRules.length > 0) {
      setBusinessRules(businessRules);
    }
  }, [businessRules]);

  return (
    <Page marginBottom="18">
      <Page.Header
        title="Reglas de negocio avanzadas"
        buttonStack={headerButtons}
      />
      {businessRules && businessRules.length > 0 ? (
        <Page.Body>
          <Table>
            <React.Fragment key=".0">
              <Table.Head>
                <Table.Row backgroundColor="neutral-surface">
                  <Table.Cell as="th" width="25%">
                    Nombre
                  </Table.Cell>
                  <Table.Cell as="th">Asociados</Table.Cell>
                  <Table.Cell as="th">Medios de envío</Table.Cell>
                  <Table.Cell as="th">Medios de pago</Table.Cell>
                  <Table.Cell as="th">Tipo</Table.Cell>
                  <Table.Cell as="th">Prioridad</Table.Cell>
                  <Table.Cell as="th">Activa</Table.Cell>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {businessRules.map((br) => {
                  return (
                    <Table.Row key={br.id}>
                      <Table.Cell>
                        <Box display="flex">
                          <ReactLink
                            key={br.id}
                            to={`/business-rules/${br.id}`}
                            color="primary"
                          >
                            <Text fontSize="base">{br.name}</Text>
                          </ReactLink>
                        </Box>
                      </Table.Cell>
                      <Table.Cell>
                        <Box display="flex" gap="1">
                          {br.cant_targets}
                          {br.cant_targets === 0 && (
                            <Icon
                              source={
                                <Tooltip
                                  content="Esta regla no tiene ningun objeto asociado!"
                                  position="top"
                                >
                                  <ExclamationTriangleIcon color="neutral-background" />
                                </Tooltip>
                              }
                              color="primary-interactive"
                            />
                          )}
                        </Box>
                      </Table.Cell>
                      <Table.Cell>
                        <Box display="flex">
                          {shippingCarries - (br.cant_shipping_carriers ?? 0)}/
                          {shippingCarries}
                        </Box>
                      </Table.Cell>
                      <Table.Cell>
                        <Box display="flex">
                          {paymentProviders - (br.cant_payment_providers ?? 0)}/
                          {paymentProviders}
                        </Box>
                      </Table.Cell>
                      <Table.Cell>
                        <Box display="flex">{t(br.type)}</Box>
                      </Table.Cell>
                      <Table.Cell>
                        <Box display="flex">{br.priority}</Box>
                      </Table.Cell>
                      <Table.Cell>
                        {!br.is_active && (
                          <Box cursor="pointer">
                            <Toggle
                              name={br.name}
                              onClick={() => handleActivateRule(br)}
                              active={br.is_active}
                            />
                          </Box>
                        )}
                        {br.is_active && !br.is_default && (
                          <Box cursor="pointer">
                            <Toggle
                              name={br.name}
                              onClick={() => handleDeactivateRule(br)}
                              active={br.is_active}
                            />
                          </Box>
                        )}
                        {br.is_default && (
                          <Box>
                            <Tag appearance="neutral">Por defecto</Tag>
                          </Box>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </React.Fragment>
          </Table>
        </Page.Body>
      ) : (
        <Title>No hay reglas de negocio</Title>
      )}
    </Page>
  );
};

export default BusinessRules;
