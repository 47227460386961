import React, { useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

const isLocalStorageAvailable = () => {
  const test = 'lerentools_test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

const initialState = {
  loading: false,
  loginStatus: isLocalStorageAvailable()
    ? Boolean(Number(localStorage.getItem('login_status')))
    : false,
  apiToken: isLocalStorageAvailable()
    ? localStorage.getItem('api_token')
    : null,
  store: {}
};

//authReducer() maneja actualizaciones del estado en diferentes acciones
const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN_START':
      return { ...state, loading: true };
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        loading: false,
        loginStatus: true,
        apiToken: action.payload.apiToken
      };
    case 'LOGIN_FAILURE':
      return { ...state, loading: false };
    case 'LOGOUT_START':
      return { ...state, loading: true };
    case 'LOGOUT_SUCCESS':
      return { ...initialState, loading: false };
    case 'SET_STORE':
      return { ...state, store: action.store };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

//createContext() para compartir el estado y funciones a componentes hijos
const AuthContext = React.createContext();

//AuthProvider envuelve a los componentes hijos con el contexto de actualización
//Se definen las funciones login y logout
const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  const login = async (user) => {
    dispatch({ type: 'LOGIN_START' });
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_BASE_URL + 'login',
        { email: user.email, password: user.password }
      );

      const apiToken = response.data.token;

      if (isLocalStorageAvailable()) {
        localStorage.setItem('api_token', apiToken);
        localStorage.setItem('login_status', 1);
      }

      axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;

      dispatch({ type: 'LOGIN_SUCCESS', payload: { apiToken } });

      return true;
    } catch (error) {
      axios.defaults.headers.common['Authorization'] = null;

      dispatch({ type: 'LOGIN_FAILURE' });

      throw error;
    }
  };

  const logout = async () => {
    dispatch({ type: 'LOGOUT_START' });

    try {
      await axios.get(process.env.REACT_APP_API_BASE_URL + 'logout');
    } catch (error) {
      // console.log("Logout error:", error);
    }

    if (isLocalStorageAvailable()) {
      localStorage.setItem('api_token', null);
      localStorage.setItem('login_status', 0);
    }

    axios.defaults.headers.common['Authorization'] = null;

    dispatch({ type: 'LOGOUT_SUCCESS' });

    return true;
  };

  const setStore = async (store) => {
    dispatch({ type: 'SET_STORE', payload: { store } });
  };

  const value = { state, login, logout, setStore };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

//useAuth() hook que permite a los componentes acceder al contexto de actualizacion
//Si se utiliza fuera del alcance del proveedor (authProvider) tira error
const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { AuthContext, AuthProvider, useAuth };
