import { connect, iAmReady } from '@tiendanube/nexo/helpers';
import { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import nexo from './nexoClient';
import NexoSyncRoute from './NexoSyncRoute';
import axios from 'axios';
import { useAuth } from './stores/auth.js';
import routes from './lib/routes/app';
import { LoginPage } from './pages';
import { DarkModeProvider, BaseLayout } from './components';
import { ToastProvider } from '@nimbus-ds/components';

function App() {
  axios.defaults.baseURL = process.env.REACT_APP_API_V2_BASE_URL;
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      if (
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.errors &&
          error.response.data.errors.some(
            (e: any) =>
              e.message && e.message.indexOf('E_UNAUTHORIZED_ACCESS') !== -1
          )) ||
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.errors &&
          error.response.data.errors.some(
            (e: any) =>
              e.message && e.message.indexOf('E_INVALID_API_TOKEN') !== -1
          )) ||
        (error &&
          error.response &&
          error.response.status == 401 &&
          error.response.statusText == 'Unauthorized')
      ) {
        // Logout if error response message contains 'E_UNAUTHORIZED_ACCESS' code
        const auth = useAuth();
        await auth.logout();
        window.location.href = '/login';
      }
      return Promise.reject(error);
    }
  );

  const { state } = useAuth();

  const [isConnect, setIsConnect] = useState(false);

  if (state.loginStatus && state.apiToken) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${state.apiToken}`;
  }

  useEffect(() => {
    connect(nexo)
      .then(async () => {
        setIsConnect(true);
        iAmReady(nexo);
      })
      .catch((e) => {
        console.log('Nexo connection fail', e);
      });
  }, []);

  if (!isConnect) {
    return (
      <div className="spinner">
        <div className="dot1"></div>
        <div className="dot2"></div>
      </div>
    );
  }

  return (
    <DarkModeProvider>
      <BrowserRouter>
        <ToastProvider>
          <BaseLayout>
            <NexoSyncRoute>
              {state.loginStatus ? (
                <Switch>
                  {routes.map((route) => (
                    <Route
                      exact
                      key={route.path}
                      path={route.path}
                      component={route.component}
                    />
                  ))}
                  <Redirect to="/business-rules" />
                </Switch>
              ) : (
                <Switch>
                  <Route path="/login" component={LoginPage} />
                  <Redirect to="/login" />
                </Switch>
              )}
            </NexoSyncRoute>
          </BaseLayout>
        </ToastProvider>
      </BrowserRouter>
    </DarkModeProvider>
  );
}

export default App;
